html {
    font-size: 2vw;
}

body {
    font-family: "Helvetica Neue", "Segoe UI", Roboto, Arial, sans-serif;
    line-height: 1.4em;
}

* {
    font-size: 1em;
}

@media print {
    @page { 
        margin: 0; 
    }
    
    html, body { 
        margin: 0; 
        -webkit-print-color-adjust: exact; 
    }
  }